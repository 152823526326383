<template>
  <div>
    <!-- !! Filter ------------------------------------------------------------------------------------------------- -->
    <b-card-actions
      ref="cardA"
      no-actions
      no-body
    >
      <b-card class="m-0">
        <b-row>
          <!-- !! Campaign -->
          <b-col cols="12" sm="3" md="3">
            <HeroVueSelect
              id="campaign-id"
              v-model="campaignId"
              label="Campaign"
              :clearable="false"
              :options="campaignOptions"
            />
          </b-col>

          <!-- !! Channel -->
          <b-col cols="12" sm="3" md="3">
            <HeroVueSelect
              id="channel-id"
              v-model="channelId"
              label="Channel"
              placeholder="All Channels"
              :clearable="true"
              :options="channelOptions"
            />
          </b-col>

          <!-- !! From Date -->
          <b-col cols="12" sm="3" md="3">
            <HeroInputDate
              id="from-date"
              v-model="fromDate"
              label="From Date"
              :add-calendar="true"
            />
          </b-col>

          <!-- !! To Date -->
          <b-col cols="12" sm="3" md="3">
            <HeroInputDate
              id="to-date"
              v-model="toDate"
              label="To Date"
              :add-calendar="true"
            />
          </b-col>

          <!-- !! Lead Type -->
          <b-col cols="12" sm="6" md="6">
            <HeroVueSelectMultiple
              id="lead-type"
              v-model="leadType"
              label="Lead Type"
              :clearable="true"
              :options="leadTypeOptions"
            />
          </b-col>

          <!-- !! Flag -->
          <b-col cols="12" sm="3" md="3">
            <HeroVueSelect
              id="flag"
              v-model="flag"
              label="Flag"
              :clearable="false"
              :options="flagOptions"
            />
          </b-col>

          <!-- !! Search -->
          <b-col cols="12" sm="3" md="3">
            <HeroInputText
              id="filter"
              v-model="search"
              label="Search"
              :clearable="true"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-card-actions>

    <!-- !! Tabs --------------------------------------------------------------------------------------------------- -->
    <b-card-actions
      ref="cardB"
      no-actions
      no-body
    >
      <b-card class="m-0">
        <b-tabs v-model="tabIndex" pills @input="doLoadAllTabData">

          <!-- !! All Leads Tab -------------------------------------------------------------------------------------- -->
          <b-tab lazy title="All Leads">
            <!-- !! Table -->
            <b-row class="my-1">
              <b-col cols="12">
                <b-table
                  show-empty
                  striped
                  sticky-header="100%"
                  :responsive="true"
                  :items="leadItems"
                  :fields="leadTableConfig.fields"
                  :per-page="0"
                  :sort-by="leadTableConfig.sortBy"
                  :sort-direction="leadTableConfig.sortDirection"
                  :no-sort-reset="true"
                  :no-local-sorting="true"
                  @sort-changed="doTableSort"
                >
                  <!-- !! Action Column -->
                  <template #cell(action)="data">
                    <!-- !! Normal Flag -->
                    <b-dropdown
                      v-if="flag === '0'"
                      variant="link"
                      toggle-class="text-decoration-none action-button"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle"
                        />
                      </template>

                      <!-- !! Mark As Spam -->
                      <b-dropdown-item
                        v-b-modal="'mark-as-spam-modal'"
                        @click="doEditData(data.item.leadId, data.item)"
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Mark As Spam</span>
                      </b-dropdown-item>

                      <!-- !! Mark As Block -->
                      <b-dropdown-item
                        v-b-modal="'mark-as-block-modal'"
                        @click="doEditData(data.item.leadId, data.item)"
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Mark As Block</span>
                      </b-dropdown-item>

                      <!-- !! Mark As Test -->
                      <b-dropdown-item
                        v-b-modal="'mark-as-test-modal'"
                        @click="doEditData(data.item.leadId, data.item)"
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Mark As Test</span>
                      </b-dropdown-item>

                      <!-- !! Add To Whitelist -->
                      <b-dropdown-item
                        v-b-modal="'add-to-whitelist-modal'"
                        @click="doEditData(data.item.leadId, data.item)"
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Add To Whitelist</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>

                  <template #cell(campaignName)="">
                    {{ campaignName }}
                  </template>

                  <template #cell(channelName)="data">
                    {{ getChannelName(data.item.channelId) }}
                  </template>

                  <template #cell(recordingUrl)="data">
                    <HeroButtonModal
                      v-if="data.item.recordingUrl"
                      v-b-modal="'call-record-modal'"
                      type="button"
                      variant="primary"
                      @click="getAudioUrl(data.item.recordingUrl, data.item)"
                    >
                      Play
                    </HeroButtonModal>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <!-- !! Table Footer -->
            <b-row>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <HeroTablePagination
                  v-model="leadTableConfig.currentPage"
                  :per-page="leadTableConfig.perPage"
                  :total-rows="leadTableConfig.totalRows"
                  @input="doTableFilter(100, 'All Leads')"
                />
              </b-col>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                <HeroTableStatus :per-page="leadTableConfig.perPage" :total-rows="leadTableConfig.totalRows" class="mr-2" />
                <HeroTablePerPage
                  v-model="leadTableConfig.perPage"
                  :options="leadTableConfig.perPageOptions"
                  @input="doTableFilter(100, 'All Leads')"
                />
              </b-col>
            </b-row>
          </b-tab>

          <!-- !! Blacklist Tab -------------------------------------------------------------------------------------- -->
          <b-tab lazy title="Blacklist">
            <!-- !! Table -->
            <b-row class="my-1">
              <b-col cols="12">
                <b-table
                  show-empty
                  striped
                  sticky-header="100%"
                  :responsive="true"
                  :items="blacklistItems"
                  :fields="blacklistTableConfig.fields"
                  :per-page="0"
                  :sort-by="blacklistTableConfig.sortBy"
                  :sort-direction="blacklistTableConfig.sortDirection"
                  :no-sort-reset="true"
                  :no-local-sorting="true"
                  @sort-changed="doTableSort"
                >
                  <!-- !! Action Column -->
                  <template #cell(action)="data">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none action-button"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle"
                        />
                      </template>

                      <!-- !! Restore Lead -->
                      <b-dropdown-item
                        v-b-modal="'restore-lead-modal'"
                        @click="doEditData(data.item.id, data.item)"
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Restore Lead</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <!-- !! Table Footer -->
            <b-row>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <HeroTablePagination
                  v-model="blacklistTableConfig.currentPage"
                  :per-page="blacklistTableConfig.perPage"
                  :total-rows="blacklistTableConfig.totalRows"
                  @input="doTableFilter(100, 'Blacklist')"
                />
              </b-col>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                <HeroTableStatus :per-page="blacklistTableConfig.perPage" :total-rows="blacklistTableConfig.totalRows" class="mr-2" />
                <HeroTablePerPage
                  v-model="blacklistTableConfig.perPage"
                  :options="blacklistTableConfig.perPageOptions"
                  @input="doTableFilter(100, 'Blacklist')"
                />
              </b-col>
            </b-row>
          </b-tab>

          <!-- !! Whitelist Tab -------------------------------------------------------------------------------------- -->
          <b-tab lazy title="Whitelist">
            <!-- !! Table -->
            <b-row class="my-1">
              <b-col cols="12">
                <b-table
                  show-empty
                  striped
                  sticky-header="100%"
                  :responsive="true"
                  :items="whitelistItems"
                  :fields="whitelistTableConfig.fields"
                  :per-page="0"
                  :sort-by="whitelistTableConfig.sortBy"
                  :sort-direction="whitelistTableConfig.sortDirection"
                  :no-sort-reset="true"
                  :no-local-sorting="true"
                  @sort-changed="doTableSort"
                >
                  <!-- !! Action Column -->
                  <template #cell(action)="data">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none action-button"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle"
                        />
                      </template>

                      <!-- !! Remove From Whitelist -->
                      <b-dropdown-item
                        v-b-modal="'remove-from-whitelist-modal'"
                        @click="doEditData(data.item.id, data.item.name)"
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Remove From Whitelist</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <!-- !! Table Footer -->
            <b-row>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <HeroTablePagination
                  v-model="whitelistTableConfig.currentPage"
                  :per-page="whitelistTableConfig.perPage"
                  :total-rows="whitelistTableConfig.totalRows"
                  @input="doTableFilter(100, 'Whitelist')"
                />
              </b-col>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                <HeroTableStatus :per-page="whitelistTableConfig.perPage" :total-rows="whitelistTableConfig.totalRows" class="mr-2" />
                <HeroTablePerPage
                  v-model="whitelistTableConfig.perPage"
                  :options="whitelistTableConfig.perPageOptions"
                  @input="doTableFilter(100, 'Whitelist')"
                />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-card-actions>

    <!-- !! Modal -->
    <MarkAsSpamModal :edit-id="editId" :edit-item="editItem" @success="doLoadAllTabData" />
    <MarkAsBlockModal :edit-id="editId" :edit-item="editItem" @success="doLoadAllTabData" />
    <MarkAsTestModal :edit-id="editId" :edit-item="editItem" @success="doLoadAllTabData" />
    <AddToWhitelistModal :edit-id="editId" :edit-item="editItem" @success="doLoadAllTabData" />
    <RemoveFromWhitelistModal :edit-id="editId" :edit-item="editItem" @success="doLoadAllTabData" />
    <RestoreLeadModal :edit-id="editId" :edit-item="editItem" @success="doLoadAllTabData" />
    <CallRecordModal :campaign-id="campaignId" :audio-url="audioUrl" :edit-item="editItem" />
  </div>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroButtonModal from '@/views/components/form/buttons/HeroButtonModal.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroInputDate from '@/views/components/form/inputs/HeroInputDate.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroVueSelectMultiple from '@/views/components/form/selects/HeroVueSelectMultiple.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import SweetAlert from '@/services/SweetAlert'
import MarkAsSpamModal from '@/views/spam-management/components/MarkAsSpamModal.vue'
import MarkAsBlockModal from '@/views/spam-management/components/MarkAsBlockModal.vue'
import MarkAsTestModal from '@/views/spam-management/components/MarkAsTestModal.vue'
import AddToWhitelistModal from '@/views/spam-management/components/AddToWhitelistModal.vue'
import RemoveFromWhitelistModal from '@/views/spam-management/components/RemoveFromWhitelistModal.vue'
import RestoreLeadModal from '@/views/spam-management/components/RestoreLeadModal.vue'
import CallRecordModal from '@/views/spam-management/components/CallRecordModal.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BCardActions,
    HeroButtonModal,
    HeroInputText,
    HeroInputDate,
    HeroVueSelect,
    HeroVueSelectMultiple,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
    MarkAsSpamModal,
    MarkAsBlockModal,
    MarkAsTestModal,
    AddToWhitelistModal,
    RemoveFromWhitelistModal,
    RestoreLeadModal,
    CallRecordModal,
  },
  data() {
    return {
      tabIndex: 0,
      audioUrl: '',
      editId: '',
      editItem: {},

      campaignId: this.$route.params.campaignId ? this.$route.params.campaignId : '',
      campaignName: '',
      channelId: '',
      fromDate: '',
      toDate: '',
      leadType: ['submitted', 'answered', 'missed-call', 'chatbot'],
      flag: '0',
      search: '',

      campaignOptions: [],
      channelOptions: [],
      leadTypeOptions: [
        { value: 'submitted', text: 'Submitted' },
        { value: 'answered', text: 'Answered' },
        { value: 'missed-call', text: 'Missed Call' },
        { value: 'chatbot', text: 'Chatbot' },
      ],
      flagOptions: [
        { value: '0', text: 'Normal Leads' },
        { value: '2', text: 'Spam Leads' },
        { value: '3', text: 'Block Leads' },
        { value: '4', text: 'Test Leads' },
      ],

      leadItems: [],
      leadTableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Action',
            key: 'action',
            stickyColumn: false,
          },
          {
            label: 'Submitted Date',
            key: 'submittedDateTime',
            sortable: false,
          },
          {
            label: 'Campaign Name',
            key: 'campaignName',
            sortable: false,
          },
          {
            label: 'Channel Name',
            key: 'channelName',
            sortable: false,
          },
          {
            label: 'Analytic Campaign Id',
            key: 'analyticCampaignId',
            sortable: false,
          },
          {
            label: 'Direct Channel Id',
            key: 'directChannelId',
            sortable: false,
          },
          {
            label: 'DID Phone',
            key: 'didPhone',
            sortable: false,
          },
          {
            label: 'DID Phone',
            key: 'didPhone',
            sortable: false,
          },
          {
            label: 'Type',
            key: 'type',
            sortable: false,
          },
          {
            label: 'First Name',
            key: 'firstName',
            sortable: false,
          },
          {
            label: 'Last Name',
            key: 'lastName',
            sortable: false,
          },
          {
            label: 'Phone',
            key: 'phone',
            sortable: false,
          },
          {
            label: 'Email',
            key: 'email',
            sortable: false,
          },
          {
            label: 'Remark',
            key: 'remark',
            sortable: false,
          },
          {
            label: 'Record',
            key: 'recordingUrl',
            sortable: false,
          },
        ],
      },

      blacklistItems: [],
      blacklistTableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Action',
            key: 'action',
            stickyColumn: false,
          },
          {
            label: 'Level',
            key: 'level',
            sortable: false,
          },
          {
            label: 'Campaign Name',
            key: 'campaignName',
            sortable: false,
          },
          {
            label: 'Type',
            key: 'type',
            sortable: false,
          },
          {
            label: 'Name',
            key: 'name',
            sortable: false,
          },
          {
            label: 'Phone',
            key: 'phone',
            sortable: false,
          },
          {
            label: 'Email',
            key: 'email',
            sortable: false,
          },
          {
            label: 'IP Address',
            key: 'ipAddress',
            sortable: false,
          },
          {
            label: 'Reason',
            key: 'reason',
            sortable: false,
          },
          {
            label: 'Created By',
            key: 'createdBy',
            sortable: false,
          },
          {
            label: 'Created At',
            key: 'createdAt',
            sortable: false,
          },
        ],
      },

      whitelistItems: [],
      whitelistTableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Action',
            key: 'action',
            stickyColumn: false,
          },
          {
            label: 'Level',
            key: 'level',
            sortable: false,
          },
          {
            label: 'Campaign Name',
            key: 'campaignName',
            sortable: false,
          },
          {
            label: 'Type',
            key: 'type',
            sortable: false,
          },
          {
            label: 'Name',
            key: 'name',
            sortable: false,
          },
          {
            label: 'Phone',
            key: 'phone',
            sortable: false,
          },
          {
            label: 'Email',
            key: 'email',
            sortable: false,
          },
          {
            label: 'IP Address',
            key: 'ipAddress',
            sortable: false,
          },
          {
            label: 'Reason',
            key: 'reason',
            sortable: false,
          },
          {
            label: 'Created By',
            key: 'createdBy',
            sortable: false,
          },
          {
            label: 'Created At',
            key: 'createdAt',
            sortable: false,
          },
        ],
      },
    }
  },
  // computed: {
  //   hasMyCampaignAccess() {
  //     return this.$can('Access - My Campaign', 'Campaigns')
  //   },
  //
  //   hasAllCampaignAccess() {
  //     return this.$can('Access - All Campaign', 'Campaigns')
  //   },
  // },
  watch: {
    async campaignId() {
      await this.getChannelOptions()
      await this.doLoadAllTabData()
    },
    async channelId() {
      await this.doLoadAllTabData()
    },
    async fromDate() {
      await this.doLoadAllTabData()
    },
    async toDate() {
      await this.doLoadAllTabData()
    },
    async leadType() {
      await this.doLoadAllTabData()
    },
    async flag() {
      await this.doLoadAllTabData()
    },
    async search() {
      await this.doLoadAllTabData()
    },
  },
  async mounted() {
    this.$refs.cardA.showLoading = true
    this.$refs.cardB.showLoading = true

    await this.getCampaignList()

    await this.doLoadAllTabData()

    this.$refs.cardA.showLoading = false
    this.$refs.cardB.showLoading = false
  },
  methods: {
    doTableFilter(timeout, tab) {
      setTimeout(() => {
        if (moment().diff(this.leadTableConfig.timeInterval, 'milliseconds') > timeout) {
          this.leadTableConfig.timeInterval = moment()
          this.doLoadData(tab)
        }
      }, timeout)
    },

    doTableSort(event) {
      this.leadTableConfig.sortBy = event.sortBy
      this.leadTableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadAllTabData() {
      if (this.tabIndex === 0) await this.doLoadData('All Leads')
      if (this.tabIndex === 1) await this.doLoadData('Blacklist')
      if (this.tabIndex === 2) await this.doLoadData('Whitelist')
    },

    async doLoadData(tab) {
      this.$refs.cardB.showLoading = true

      const queryString = this.getQueryString(tab)
      const axiosConfig = AxiosConfig.getLeadManagementConfig()

      if (tab === 'All Leads' && this.campaignId) {
        try {
          await this.getCampaignName()
          await this.getChannelOptions()
          const url = `${process.env.VUE_APP_LEAD_SERVICE_API}lead-management/load-all-leads?${queryString}`
          const response = await axios.get(url, axiosConfig)

          this.leadTableConfig.currentPage = Number(response.data.current_page)
          this.leadTableConfig.perPage = Number(response.data.per_page)
          this.leadTableConfig.totalRows = Number(response.data.total)
          this.leadItems = response.data.data
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

          if (error.response.status === 401) {
            await this.$router.push({ name: 'heroai-logout' })
          }

          this.leadTableConfig.currentPage = 1
          this.leadTableConfig.perPage = 25
          this.leadTableConfig.totalRows = 0
        }
      }

      if (tab === 'Blacklist') {
        try {
          const url = `${process.env.VUE_APP_LEAD_SERVICE_API}lead-management/load-blacklist?${queryString}`
          const response = await axios.get(url, axiosConfig)

          this.blacklistTableConfig.currentPage = Number(response.data.current_page)
          this.blacklistTableConfig.perPage = Number(response.data.per_page)
          this.blacklistTableConfig.totalRows = Number(response.data.total)
          this.blacklistItems = response.data.data
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

          if (error.response.status === 401) {
            await this.$router.push({ name: 'heroai-logout' })
          }

          this.blacklistTableConfig.currentPage = 1
          this.blacklistTableConfig.perPage = 25
          this.blacklistTableConfig.totalRows = 0
        }
      }

      if (tab === 'Whitelist') {
        try {
          const url = `${process.env.VUE_APP_LEAD_SERVICE_API}lead-management/load-whitelist?${queryString}`
          const response = await axios.get(url, axiosConfig)

          this.whitelistTableConfig.currentPage = Number(response.data.current_page)
          this.whitelistTableConfig.perPage = Number(response.data.per_page)
          this.whitelistTableConfig.totalRows = Number(response.data.total)
          this.whitelistItems = response.data.data
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

          if (error.response.status === 401) {
            await this.$router.push({ name: 'heroai-logout' })
          }

          this.whitelistTableConfig.currentPage = 1
          this.whitelistTableConfig.perPage = 25
          this.whitelistTableConfig.totalRows = 0
        }
      }

      this.$refs.cardB.showLoading = false
    },

    doEditData(id, item) {
      this.editId = id
      this.editItem = item
    },

    getAudioUrl(url, item) {
      this.audioUrl = url
      this.editItem = item
    },

    getQueryString(tab) {
      let queryString = ''

      if (this.campaignId) {
        queryString = `campaignIds=${this.campaignId}`
      }

      if (tab === 'All Leads') {
        queryString += `&page=${this.leadTableConfig.currentPage}`
        queryString += `&perPage=${this.leadTableConfig.perPage}`
      } else if (tab === 'Blacklist') {
        queryString += `&page=${this.blacklistTableConfig.currentPage}`
        queryString += `&perPage=${this.blacklistTableConfig.perPage}`
      } else {
        queryString += `&page=${this.whitelistTableConfig.currentPage}`
        queryString += `&perPage=${this.whitelistTableConfig.perPage}`
      }

      if (this.channelId) {
        queryString += `&channelId=${this.channelId}`
      }

      if (this.fromDate) {
        queryString += `&filterDateFrom=${this.fromDate}T00:00:00%2B0700`
      }

      if (this.toDate) {
        queryString += `&filterDateTo=${this.toDate}T23:59:59%2B0700`
      }

      if (this.search) {
        queryString += `&q=${this.search}`
      }

      if (this.leadType.length > 0) {
        let subQuery = ''

        this.leadType.forEach(type => {
          if (subQuery === '') {
            subQuery = `&filterTypes=${type}`
          } else {
            subQuery += `,${type}`
          }
        })

        queryString += subQuery
      }

      if (tab === 'All Leads') {
        queryString += `&flag=${this.flag}`
      }

      if (tab === 'Blacklist') {
        queryString += '&flags=2,3'
      }

      return queryString
    },

    async getCampaignList() {
      try {
        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)
        const response = await axios.get('dropdown-options/campaign-options-by-user-roles?orderByColumn=name&orderByDirection=asc', axiosConfig)
        // if (response.data.data) {
        //   if (this.hasMyCampaignAccess && !this.hasAllCampaignAccess) {
        //     const creatorId = store.getters['heroAiAuthentications/loginData']?.id
        //     const { data } = await axios(Constant.apiURL.campaign.list, {
        //       method: 'post',
        //       headers: axiosConfig.headers,
        //       data: {
        //         creator_id: creatorId,
        //       },
        //     })
        //
        //     if (data.status) {
        //       const myCampaigns = data.data.data
        //       const campaigns = response.data.data.filter(campaign => myCampaigns.some(myCampaign => myCampaign.campaign_id === campaign.id))
        //
        //       this.campaignOptions = campaigns.map(campaign => ({
        //         text: campaign.name,
        //         value: campaign.id,
        //       }))
        //
        //       return
        //     }
        //     return
        //   }
        //
        this.campaignOptions = response.data.data.map(x => ({
          text: x.name,
          value: x.id,
        }))
        // }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async getChannelOptions() {
      try {
        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)
        const response = await axios.get(`dropdown-options/channel-options?campaignId=${this.campaignId}&orderByColumn=name&orderByDirection=asc&perPage=100`, axiosConfig)

        if (response.data.data.data) {
          this.channelOptions = response.data.data.data.map(channel => ({
            text: channel.name,
            value: channel.id,
          }))
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async getCampaignName() {
      this.campaignName = this.$store.getters['CampaignOptions/getCampaignName'](this.campaignId)
    },

    getChannelName(channelId) {
      const channel = this.channelOptions.find(object => object.value === channelId)

      if (channel) {
        return channel.text
      }

      return '-'
    },
  },
}
</script>
